<template>
  <section class="ls-page">
    <div class="ps-section__header ls-page-header-no-bg" style="background-image: url(../img/cover_dialfrom263.jpg);">
    </div>
    <div class="ps-section__content ls-page-content mb-5">
      <h3 class="container ls-page-header-subtitle">
        Cheapest Diaspora calls to Zimbabwe. <br/>Very convenient online 24/7 top up to your 263 number.<br/>No more expensive calls or huge bills to worry about.
      </h3>
      <router-view></router-view>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
export default {
  name: "BaseDialFrom263",
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
  }
};
</script>

<style scoped></style>
